import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
// import { DiscordIcon, InstagramIcon, MediumIcon, TwitterIcon } from 'app/components/Icon'
// import { DiscordIcon, InstagramIcon, TwitterIcon } from 'app/components/Icon'
import { DiscordIcon, MediumIcon, TwitterIcon } from 'app/components/Icon'
import Typography from 'app/components/Typography'
// import { Feature } from 'app/enums'
// import { featureEnabled } from 'app/functions'
import { useActiveWeb3React } from 'app/services/web3'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import Container from '../Container'

const Footer = () => {
  const { chainId } = useActiveWeb3React()
  const { i18n } = useLingui()

  return (
    <div className="z-10 w-full footerMain">
      <Container className="px-6 mx-f100 max-w-2xl w-full">
        <div className="flex grid flex-row-reverse float-right grid-cols-2 gap-10 footerSpacing md:grid-cols-2 lg:grid-cols-4 xs:px-4 border_top">
          <div className="flex flex-col gap-6 pt-6">
            <div className="flex items-center justify-start gap-2">
              <div className="">
                <Image src="/images/swapped-Icon.png" alt="Swapped logo" width="28px" height="28px" />
              </div>
              {/* <Typography variant="h2" weight={700} className="tracking-[0.02em] scale-y-90 hover:text-high-emphesis">
                Swapped Finance
              </Typography> */}
            </div>
            <Typography variant="xs" className="text-high-emphesis">
              {i18n._(t`Our community is building a comprehensive decentralized trading platform for the future of finance. Join
              us!`)}
            </Typography>
            <div className="flex items-center gap-4">
              <a href="https://twitter.com/SwappedFinance" target="_blank" rel="noreferrer">
                <TwitterIcon width={16} className="text-high-emphesis" />
              </a>
              {/* <a href="https://instagram.com/swapsicledex?utm_medium=copy_link" target="_blank" rel="noreferrer">
                <InstagramIcon width={16} className="text-low-emphesis" />
              </a> */}
              <a href="https://medium.com/@swappedfinance" target="_blank" rel="noreferrer">
                <MediumIcon width={16} className="text-high-emphesis" />
              </a>
              <a href="https://discord.gg/etbpV4SpGj" target="_blank" rel="noreferrer">
                <DiscordIcon width={16} className="text-white" />
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-6 pt-6">
            <Typography variant="xs" weight={700} className="mt-2.5 text-high-emphesis">
              {i18n._(t`Products`)}
            </Typography>
            <a href="http://faucet.swapped.finance" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Faucet`)}
              </Typography>
            </a>
            <Link href="/swap" passHref={true}>
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Swap`)}
              </Typography>
            </Link>
            <Link href={'/add/ETH/'} passHref={true}>
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Liquidity Pools`)}
              </Typography>
            </Link>
            <Link href="/farm" passHref={true}>
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Farming`)}
              </Typography>
            </Link>
            {/* <Link href="/miso" passHref={true}>
              <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis">
                {i18n._(t`Launchpad`)}
              </Typography>
            </Link>
            <a href="https://shoyunft.com" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis">
                {i18n._(t`Shoyu NFT`)}
              </Typography>
            </a>
            <Link href="/tools" passHref={true}>
              <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis">
                {i18n._(t`Tools`)}
              </Typography>
            </Link> */}
          </div>
          <div className="flex flex-col gap-6 pt-6">
            <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis">
              {i18n._(t`Help`)}
            </Typography>
            <a href="#" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Whitepaper`)}
              </Typography>
            </a>
            <a href="https://twitter.com/SwappedFinance" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Ask on Twitter`)}
              </Typography>
            </a>
            <a href="https://discord.gg/etbpV4SpGj" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Ask on discord`)}
              </Typography>
            </a>
          </div>
          {/* <div className="flex flex-col gap-6 pt-6 border_top">
            <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis">
              {i18n._(t`Developers`)}
            </Typography>
            <a href="#" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis">
                {i18n._(t`GitHub`)}
              </Typography>
            </a>
          </div> */}
          <div className="flex flex-col gap-6 pt-6">
            <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis">
              {i18n._(t`Legals`)}
            </Typography>
            <a href="#" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Privacy`)}
              </Typography>
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Terms and conditions`)}
              </Typography>
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="hover:text-low-emphesis text-high-emphesis">
                {i18n._(t`Terms of use`)}
              </Typography>
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
